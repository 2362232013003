import React from 'react'
import Title from 'components/Title'
import Container from 'components/Container'
// import ExternalLink from 'components/ExternalLink'
import Footer from "components/Footer"
import Header from "components/Header"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import { DateTime } from 'luxon'

export default () => {

    const { t } = useTranslation()

    const { title } = useSiteMetadata()

    return (
        <>
        <Header/>
        <main className='main-content'>
            <Title dark title={t('Privacy policy')} subtitle={`${ t(`Updated:`) } ${ DateTime.fromISO('2020-12-07').toLocaleString(DateTime.DATE_FULL) }`} />

            {/* <Container>
                <iframe 
                    src="https://cdn.forms-content.sg-form.com/8e4ae6f6-2a8a-11eb-a1d1-4e330953a988" 
                    frameBorder='0'
                    width='100%'
                    height="544"
                    title='contact-us'
                />
            </Container> */}

            <Container>

                <p>{ t(`{{ siteTitle }} takes your privacy seriously. We will never sell your information. Here's what we do with the information collected through the {{ siteTitle }} service.`, { siteTitle: title }) }</p>

                <h2>{ t(`Updates to the Privacy Policy`) }</h2>

                <p>
                    {t(`privacy`, `We may update this Privacy Policy occasionally. Any time this policy is updated we will notify you by revising the date above. If the policy has been changed in any way that affects you practically, we will provide additional notice (in the form of adding a statement to our website, or sending you an email). To help protect your privacy, we encourage you to regularily review the Privacy Policy to stay informed about our information practices.`)}
                </p>

                <h2>{ t(`How we collect information`) }</h2>

                <h3>{ t(`Information you provide`) }</h3>

                <p>
                    { t(`We obtain information about you when you use our Service, for example: When you create an account, submit billing information, contact us, upload or access images and create remote storage sources.`) }
                </p>

                <h3>{ t(`Information we collect automatically`) }</h3>

                <p>
                    { t(`When you access or use the Services we automatically collect information including:`) }
                    <ul>
                        <li><strong>{ t(`Log information:`) }</strong> { t(`We log information about your use of the Service (the type of web browser and operating system you use, access times, pages viewed, your IP address and the site that referred you to us).`) }</li>
                        <li><strong>{ t(`Tracking information:`) }</strong> { t(`We and our service providers use various technologies for the purposes of understanding how you use our Service, help us debug any error you might experience and check if you have received and/or opened an email from us.`) }</li>
                    </ul>
                </p>

                <h2>{ t(`How we use your information`) }</h2>

                <p>
                    { t(`We may use your information to:`) }
                    <ul>
                        <li>{ t(`Process a subscription that you have submitted`) }</li>
                        <li>{ t(`Carry out our oblications arising from any contracts entered into by you and us`) }</li>
                        <li>{ t(`Seek your views or comments on the services we provide`) }</li>
                        <li>{ t(`Notify you of changes to our services`) }</li>
                        <li>{ t(`Send you communications which you have requested, such as information about discounts and new products that might be of interest to you`) }</li>
                        <li>{ t(`Display personalized adverts for {{ siteTitle }} via remarketing`, { siteTitle: title }) }</li>
                        <li>{ t(`Process a grant or job application`) }</li>
                    </ul>
                    { t(`We regularily review our retention periods for personal information. We are legally required to hold some types of information. We will hold your personal information on our systems for as long as is necessary for the relevant activity, or as long as is set out in any relevant contract you hold with us.`) }
                </p>

                <h2>{ t(`Who has access to your information`) }</h2>
                <p>{ t(`We will not sell or rent your information to third parties.`) }</p>
                <p>{ t(`When you pay for a subscription to {{ siteTitle }}, your payment will be processed by a third party payment processor who specializes in the secure online capture and processing of credit/debit transactions.`, { siteTitle: title }) }</p>
                <p>{ t(`We use analytics to help us understand how our customers use our website. This information is anonymized and we are not able to trace your browsing activity to you.`) }</p>
                <p>{ t(`We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of any business restructuring or reorganization, or if we are under a duty to disclose or share your personal data in order to comply with any legal obligation or to enforce or apply our terms of use or to protect the rights, property or safety of our supporters and customers. However we will takes steps with the aim of ensuring that your privacy rights continue to be protected.`) }</p>
                <p>{ t(`If you provide feedback about our service, your comments, name and website address may be included in our marketing communications. We will ask you for permission before publishing any such information.`) }</p>

                <h2>{ t(`Security`) }</h2>

                <p>{ t(`All information collected on this website is stored on restricted servers.`) }</p>
                <p>{ t(`{{ siteTitle }} takes all resonable measures to protect against the unauthorised access, use, alteration or dstruction of potentially personally-identifying and personally-identifying information.`, { siteTitle: title }) }</p>
                <p>{ t(`Our servers are located in the United States. Any personally-identifiable information you enter into the website will be transferred out of your country and into the United States. You consent to such transfer through your use of {{ siteTitle }}. You also warrant that you have the right to transfer such information outside your country and into the United States.`, { siteTitle: title }) }</p>

                <h2>{ t(`Email opt-out`) }</h2>
                <p>{ t(`We send emails to our customers to keep them informed of their account usage and provide information about the service. You may opt-out of this at any time via the unsubscribe link in each email.`) }</p>

                <h2>{ t(`Data rights`) }</h2>
                <p>{ t(`You have rights with how your Personal Data is processed, which are described below. In some circumstances, we may not be able to fully comply with your requests, or we may ask you to provide us with additional information in connection with your request, which may be Personal Data, for example, if we need to verify your identity or the nature of your request. In such situations, however, we will still respond to let you know of our decision.`) }</p>
                <ul>
                    <li>{ t(`Right to be informed - You have the right to know how your data is being processed.`) }</li>
                    <li>{ t(`Right of Access - You have the right to access and request copies of your data that is being collected.`) }</li>
                    <li>{ t(`Right of Rectification - You have the right to modify or update your personal data if incorrect data is being used.`) }</li>
                    <li>{ t(`Right to Erasure - You have the right to delete any personal data that has been collected.`) }</li>
                    <li>{ t(`Right to Restriction - You have the right to prevent a company from processing certain types of personal data.`) }</li>
                    <li>{ t(`Right to Data Portability - You have the right to access your personal data and transfer it between controllers.`) }</li>
                    <li>{ t(`Right to Object - You have the right to withdraw consent to the processing of your data for public interest, marketing or research.`) }</li>
                    <li>{ t(`Right to Reject Automated Decisions - You have the right to object to your data being processed by automatic systems.`) }</li>
                </ul>

                <p>{ t(`To make any of the requests above, contact us at`) } <a href="mailto:privacy@napaconnect.ca">privacy@napaconnect.ca</a></p>

            </Container>

        </main>
        <Footer/>
        </>
    )
}